.logo {
	width: 30%;
	max-width: 100px;
}

.heading {
	text-align: center;
}

.form {
	width: 100%;
}