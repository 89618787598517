.totalSurveyed {
	padding: 30px;
	text-align: center;
}

.diabeticsIdentified {
	padding: 30px;
	text-align: center;
}

.table,
.tableRow {
	text-align: center;
}

.cardHeadingTotal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background-color: var(--mantine-color-violet-9);
	color: #fff;
	font-weight: bold;
}

.cardHeadingToday {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background-color: var(--mantine-color-pink-9);
	color: #fff;
	font-weight: bold;
}

.diabeticsCell {
	background-color: var(--mantine-color-red-9);
	color: var(--mantine-color-white);
	font-weight: 700;
}

.totalCell {
	background-color: var(--mantine-color-blue-9);
	color: var(--mantine-color-white);
	font-weight: 700;
}