.input {
  padding-left: 28px;
  padding-right: 28px;
  height: 100px;
  font-size: 28px;
}

.maxWidthContainer {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.mantine-Card-root, 
.mantine-Paper-root {
  width: 100% !important;
}
