.header {
	border-bottom: 1px solid #ddd;
	box-shadow: rgba(9, 11, 17, 0.05) 0px 5px 15px 0px;
	padding: 10px;
	display: flex;
	justify-content: center;
}

.headerLogoContainer {
	display: inline-flex;
}

.headerLogo {
	height: 50px;
}

.headerNameContainer {
	display: inline-flex;
	padding: 0 10px;
	flex-grow: 4;
}
